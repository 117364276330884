/* .carousel :global .swiper {
  min-height: 720px;
}

@media (max-width: 992px) {
  .carousel :global .swiper {
    min-height: 530px;
  }
}

@media (max-width: 640px) {
  .carousel :global .swiper {
    min-height: 550px;
  }
} */

.carousel :global .swiper-button-next {
  background-image: url(/images/arrow-next.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.carousel :global .swiper-button-next,
.carousel :global .swiper-rtl .swiper-button-prev {
  right: 5%;
  top: 45%;
}

.carousel :global .swiper-button-next::after {
  display: none;
}

.carousel :global .swiper-button-prev {
  background-image: url(/images/arrow-prev.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

@media (max-width: 600px) {
  .carousel :global .swiper-button-next {
    display: none;
  }
}

.carousel :global .swiper-button-prev {
  background-image: url(/images/arrow-prev.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.carousel :global .swiper-button-prev,
.carousel :global .swiper-rtl .swiper-button-next {
  left: 5%;
  top: 45%;
}

.carousel :global .swiper-button-prev::after {
  display: none;
}

@media (max-width: 600px) {
  .carousel :global .swiper-button-prev {
    display: none;
  }
}

.carousel :global .swiper-pagination-bullet {
  background: #f2f2f2;
}
