.gwd-div-dl43 {
  position: absolute;
  left: 50%;
  top: 50%;
  border-image-source: none;
  border-image-width: 1;
  border-image-outset: 0;
  border-image-repeat: stretch;
  transform: translate(-50%, -50%) translate3d(0px, 0px, 0px);
  -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px);
  -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px);
}
@keyframes gwd-gen-h83cgwdanimation_gwd-keyframes {
  0% {
      transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateX(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateX(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateX(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  3.1111% {
      transform: translate(-50%, -50%) translate3d(2px, -2px, 0px) rotateX(7.8873deg);
      -webkit-transform: translate(-50%, -50%) translate3d(2px, -2px, 0px) rotateX(7.8873deg);
      -moz-transform: translate(-50%, -50%) translate3d(2px, -2px, 0px) rotateX(7.8873deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  7.8889% {
      transform: translate(-50%, -50%) translate3d(5px, 0px, 0px) rotateX(20deg);
      -webkit-transform: translate(-50%, -50%) translate3d(5px, 0px, 0px) rotateX(20deg);
      -moz-transform: translate(-50%, -50%) translate3d(5px, 0px, 0px) rotateX(20deg);
      animation-timing-function: ease-out;
      -webkit-animation-timing-function: ease-out;
      -moz-animation-timing-function: ease-out;
  }
  18.8889% {
      transform: translate(-50%, -50%) translate3d(5px, 0px, 0px) rotateX(8.1413deg);
      -webkit-transform: translate(-50%, -50%) translate3d(5px, 0px, 0px) rotateX(8.1413deg);
      -moz-transform: translate(-50%, -50%) translate3d(5px, 0px, 0px) rotateX(8.1413deg);
      animation-timing-function: ease-out;
      -webkit-animation-timing-function: ease-out;
      -moz-animation-timing-function: ease-out;
  }
  71.8889% {
      transform: translate(-50%, -50%) translate3d(5px, 0px, 0px) rotateX(-10deg);
      -webkit-transform: translate(-50%, -50%) translate3d(5px, 0px, 0px) rotateX(-10deg);
      -moz-transform: translate(-50%, -50%) translate3d(5px, 0px, 0px) rotateX(-10deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  73.2222% {
      transform: translate(-50%, -50%) translate3d(5px, 0px, 100px) rotateX(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(5px, 0px, 100px) rotateX(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(5px, 0px, 100px) rotateX(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  73.3333% {
      transform: translate(-50%, -50%) translate3d(4px, 0px, 99px) rotateX(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(4px, 0px, 99px) rotateX(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(4px, 0px, 99px) rotateX(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  100% {
      transform: translate(-50%, -50%) translate3d(5px, 0px, 0px) rotateX(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(5px, 0px, 0px) rotateX(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(5px, 0px, 0px) rotateX(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
}
@-webkit-keyframes gwd-gen-h83cgwdanimation_gwd-keyframes {
  0% {
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateX(0deg);
      -webkit-animation-timing-function: linear;
  }
  3.1111% {
      -webkit-transform: translate(-50%, -50%) translate3d(2px, -2px, 0px) rotateX(7.8873deg);
      -webkit-animation-timing-function: linear;
  }
  7.8889% {
      -webkit-transform: translate(-50%, -50%) translate3d(5px, 0px, 0px) rotateX(20deg);
      -webkit-animation-timing-function: ease-out;
  }
  18.8889% {
      -webkit-transform: translate(-50%, -50%) translate3d(5px, 0px, 0px) rotateX(8.1413deg);
      -webkit-animation-timing-function: ease-out;
  }
  71.8889% {
      -webkit-transform: translate(-50%, -50%) translate3d(5px, 0px, 0px) rotateX(-10deg);
      -webkit-animation-timing-function: linear;
  }
  73.2222% {
      -webkit-transform: translate(-50%, -50%) translate3d(5px, 0px, 100px) rotateX(0deg);
      -webkit-animation-timing-function: linear;
  }
  73.3333% {
      -webkit-transform: translate(-50%, -50%) translate3d(4px, 0px, 99px) rotateX(0deg);
      -webkit-animation-timing-function: linear;
  }
  100% {
      -webkit-transform: translate(-50%, -50%) translate3d(5px, 0px, 0px) rotateX(0deg);
      -webkit-animation-timing-function: linear;
  }
}
@-moz-keyframes gwd-gen-h83cgwdanimation_gwd-keyframes {
  0% {
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateX(0deg);
      -moz-animation-timing-function: linear;
  }
  3.1111% {
      -moz-transform: translate(-50%, -50%) translate3d(2px, -2px, 0px) rotateX(7.8873deg);
      -moz-animation-timing-function: linear;
  }
  7.8889% {
      -moz-transform: translate(-50%, -50%) translate3d(5px, 0px, 0px) rotateX(20deg);
      -moz-animation-timing-function: ease-out;
  }
  18.8889% {
      -moz-transform: translate(-50%, -50%) translate3d(5px, 0px, 0px) rotateX(8.1413deg);
      -moz-animation-timing-function: ease-out;
  }
  71.8889% {
      -moz-transform: translate(-50%, -50%) translate3d(5px, 0px, 0px) rotateX(-10deg);
      -moz-animation-timing-function: linear;
  }
  73.2222% {
      -moz-transform: translate(-50%, -50%) translate3d(5px, 0px, 100px) rotateX(0deg);
      -moz-animation-timing-function: linear;
  }
  73.3333% {
      -moz-transform: translate(-50%, -50%) translate3d(4px, 0px, 99px) rotateX(0deg);
      -moz-animation-timing-function: linear;
  }
  100% {
      -moz-transform: translate(-50%, -50%) translate3d(5px, 0px, 0px) rotateX(0deg);
      -moz-animation-timing-function: linear;
  }
}
.mysteryBoxOpening1 .gwd-gen-h83cgwdanimation {
  animation: 9s linear 0s 1 normal forwards gwd-gen-h83cgwdanimation_gwd-keyframes;
  -webkit-animation: 9s linear 0s 1 normal forwards gwd-gen-h83cgwdanimation_gwd-keyframes;
  -moz-animation: 9s linear 0s 1 normal forwards gwd-gen-h83cgwdanimation_gwd-keyframes;
}
@keyframes gwd-gen-91kvgwdanimation_gwd-keyframes {
  0% {
      opacity: 0.2;
      transform: translate(-50%, -50%) translate3d(0px, 0px, -3000px);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, -3000px);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, -3000px);
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(0%);
      -webkit-filter: opacity(0%);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  4.1667% {
      opacity: 0.2;
      transform: translate(-50%, -50%) translate3d(0px, 0px, -117px);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, -117px);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, -117px);
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  83.3333% {
      opacity: 0.0348;
      transform: translate(-50%, -50%) translate3d(0px, 0px, -117px);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, -117px);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, -117px);
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  100% {
      opacity: 0;
      transform: translate(-50%, -50%) translate3d(0px, 0px, -117px);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, -117px);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, -117px);
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(0%);
      -webkit-filter: opacity(0%);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
}
@-webkit-keyframes gwd-gen-91kvgwdanimation_gwd-keyframes {
  0% {
      opacity: 0.2;
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, -3000px);
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(0%);
      -webkit-animation-timing-function: linear;
  }
  4.1667% {
      opacity: 0.2;
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, -117px);
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-animation-timing-function: linear;
  }
  83.3333% {
      opacity: 0.0348;
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, -117px);
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-animation-timing-function: linear;
  }
  100% {
      opacity: 0;
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, -117px);
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(0%);
      -webkit-animation-timing-function: linear;
  }
}
@-moz-keyframes gwd-gen-91kvgwdanimation_gwd-keyframes {
  0% {
      opacity: 0.2;
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, -3000px);
      --gwd-hidden-css-filter: none;
      filter: opacity(0%);
      -moz-animation-timing-function: linear;
  }
  4.1667% {
      opacity: 0.2;
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, -117px);
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-animation-timing-function: linear;
  }
  83.3333% {
      opacity: 0.0348;
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, -117px);
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-animation-timing-function: linear;
  }
  100% {
      opacity: 0;
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, -117px);
      --gwd-hidden-css-filter: none;
      filter: opacity(0%);
      -moz-animation-timing-function: linear;
  }
}
@keyframes gwd-gen-1jm7gwdanimation_gwd-keyframes {
  0% {
      transform: translate(-50%, -50%) rotateZ(0deg);
      -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
      -moz-transform: translate(-50%, -50%) rotateZ(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  8.5714% {
      transform: translate(-50%, -50%) rotateZ(0deg);
      -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
      -moz-transform: translate(-50%, -50%) rotateZ(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  100% {
      transform: translate(-50%, -50%) rotateZ(300deg);
      -webkit-transform: translate(-50%, -50%) rotateZ(300deg);
      -moz-transform: translate(-50%, -50%) rotateZ(300deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
}
@-webkit-keyframes gwd-gen-1jm7gwdanimation_gwd-keyframes {
  0% {
      -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
      -webkit-animation-timing-function: linear;
  }
  8.5714% {
      -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
      -webkit-animation-timing-function: linear;
  }
  100% {
      -webkit-transform: translate(-50%, -50%) rotateZ(300deg);
      -webkit-animation-timing-function: linear;
  }
}
@-moz-keyframes gwd-gen-1jm7gwdanimation_gwd-keyframes {
  0% {
      -moz-transform: translate(-50%, -50%) rotateZ(0deg);
      -moz-animation-timing-function: linear;
  }
  8.5714% {
      -moz-transform: translate(-50%, -50%) rotateZ(0deg);
      -moz-animation-timing-function: linear;
  }
  100% {
      -moz-transform: translate(-50%, -50%) rotateZ(300deg);
      -moz-animation-timing-function: linear;
  }
}
.mysteryBoxOpening1 .gwd-gen-1jm7gwdanimation {
  animation: 7s linear 0s 1 normal forwards gwd-gen-1jm7gwdanimation_gwd-keyframes;
  -webkit-animation: 7s linear 0s 1 normal forwards gwd-gen-1jm7gwdanimation_gwd-keyframes;
  -moz-animation: 7s linear 0s 1 normal forwards gwd-gen-1jm7gwdanimation_gwd-keyframes;
}
[data-gwd-group="Sun"] .gwd-gen-91kvgwdanimation {
  animation: 6s linear 1s 1 normal forwards gwd-gen-91kvgwdanimation_gwd-keyframes;
  -webkit-animation: 6s linear 1s 1 normal forwards gwd-gen-91kvgwdanimation_gwd-keyframes;
  -moz-animation: 6s linear 1s 1 normal forwards gwd-gen-91kvgwdanimation_gwd-keyframes;
}
[data-gwd-group="Sun"] .gwd-grp-tm9k.gwd-img-1wrk {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 600px;
  height: 600px;
  opacity: 0.2;
  transform: translate(-50%, -50%) translate3d(0px, 0px, -3000px);
  -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, -3000px);
  -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, -3000px);
  filter: opacity(0%);
  -webkit-filter: opacity(0%);
}
[data-gwd-group="Sun"] {
  width: 594px;
  height: 596px;
}
[data-gwd-group="Cards"] {
  width: 176px;
  height: 280px;
}
.gwd-div-1n5d {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotateZ(0deg);
  -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
  -moz-transform: translate(-50%, -50%) rotateZ(0deg);
}
[data-gwd-group="Cards"] {
  width: 176px;
  height: 280px;
}

[data-gwd-group="Cards"] {
  width: 140px;
  height: 256px;
}
[data-gwd-group="Cards"] {
  width: 140px;
  height: 256px;
}
[data-gwd-group="Card1"] {
  width: 140px;
  height: 256px;
}
[data-gwd-group="Card1"] .gwd-grp-1lag.gwd-div-15hw {
  position: absolute;
  padding: 0px 10px 34px;
  left: 50%;
  top: 100%;
  transform: translate(-50%, -100%) translate3d(0px, -20px, 0px);
  -webkit-transform: translate(-50%, -100%) translate3d(0px, -20px, 0px);
  -moz-transform: translate(-50%, -100%) translate3d(0px, -20px, 0px);
}
[data-gwd-group="Card1"] .gwd-grp-1lag.gwd-img-18nz {
  position: absolute;
  width: 140px;
  height: 140px;
  left: 50%;
  padding: 10px;
  top: 10px;
  transform: translate(-50%, 0%);
  -webkit-transform: translate(-50%, 0%);
  -moz-transform: translate(-50%, 0%);
}
@keyframes gwd-gen-1tlygwdanimation_gwd-keyframes {
  0% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  98.5294% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(0%);
      -webkit-filter: opacity(0%);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
}
@-webkit-keyframes gwd-gen-1tlygwdanimation_gwd-keyframes {
  0% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-animation-timing-function: linear;
  }
  98.5294% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(0%);
      -webkit-animation-timing-function: linear;
  }
}
@-moz-keyframes gwd-gen-1tlygwdanimation_gwd-keyframes {
  0% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-animation-timing-function: linear;
  }
  98.5294% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css-filter: none;
      filter: opacity(0%);
      -moz-animation-timing-function: linear;
  }
}
[data-gwd-group="Card1"] .gwd-gen-1tlygwdanimation {
  animation: 0.68s linear 0s 1 normal forwards gwd-gen-1tlygwdanimation_gwd-keyframes;
  -webkit-animation: 0.68s linear 0s 1 normal forwards gwd-gen-1tlygwdanimation_gwd-keyframes;
  -moz-animation: 0.68s linear 0s 1 normal forwards gwd-gen-1tlygwdanimation_gwd-keyframes;
}
@keyframes gwd-gen-3uwtgwdanimation_gwd-keyframes {
  0% {
      visibility: hidden;
      animation-timing-function: step-end;
      -webkit-animation-timing-function: step-end;
      -moz-animation-timing-function: step-end;
  }
  29.7556% {
      visibility: inherit;
      animation-timing-function: step-end;
      -webkit-animation-timing-function: step-end;
      -moz-animation-timing-function: step-end;
  }
  29.7778% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%) brightness(1000%);
      -webkit-filter: opacity(100%) brightness(1000%);
      transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(-84deg);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(-84deg);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(-84deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  30.5556% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%) brightness(100%);
      -webkit-filter: opacity(100%) brightness(100%);
      transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  34% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%) brightness(100%);
      -webkit-filter: opacity(100%) brightness(100%);
      transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  35.5556% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%) brightness(100%);
      -webkit-filter: opacity(100%) brightness(100%);
      transform: translate(-50%, -50%) translate3d(-420px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(-420px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(-420px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  72% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(1) brightness(1);
      -webkit-filter: opacity(1) brightness(1);
      transform: translate(-50%, -50%) translate3d(-420px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(-420px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(-420px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  73.1111% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%) brightness(1000%);
      -webkit-filter: opacity(100%) brightness(1000%);
      transform: translate(-50%, -50%) translate3d(-420px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(-420px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(-420px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  74.2222% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(1) brightness(1);
      -webkit-filter: opacity(1) brightness(1);
      transform: translate(-50%, -50%) translate3d(-420px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(-420px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(-420px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  95.5556% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(1) brightness(1);
      -webkit-filter: opacity(1) brightness(1);
      transform: translate(-50%, -50%) translate3d(-420px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(-420px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(-420px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  99.9778% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(1) brightness(100%);
      -webkit-filter: opacity(1) brightness(100%);
      transform: translate(-50%, -50%) translate3d(-420px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(-420px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(-420px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(1) brightness(100%);
      -webkit-filter: opacity(1) brightness(100%);
      transform: translate(-50%, -50%) translate3d(-420px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(-420px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(-420px, 0px, 0px) rotateY(0deg);
      visibility: visible;
  }
}
@-webkit-keyframes gwd-gen-3uwtgwdanimation_gwd-keyframes {
  0% {
      visibility: hidden;
      -webkit-animation-timing-function: step-end;
  }
  29.7556% {
      visibility: inherit;
      -webkit-animation-timing-function: step-end;
  }
  29.7778% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%) brightness(1000%);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(-84deg);
      -webkit-animation-timing-function: linear;
  }
  30.5556% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%) brightness(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  34% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%) brightness(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  35.5556% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%) brightness(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(-420px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  72% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(1) brightness(1);
      -webkit-transform: translate(-50%, -50%) translate3d(-420px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  73.1111% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%) brightness(1000%);
      -webkit-transform: translate(-50%, -50%) translate3d(-420px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  74.2222% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(1) brightness(1);
      -webkit-transform: translate(-50%, -50%) translate3d(-420px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  95.5556% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(1) brightness(1);
      -webkit-transform: translate(-50%, -50%) translate3d(-420px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  99.9778% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(1) brightness(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(-420px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(1) brightness(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(-420px, 0px, 0px) rotateY(0deg);
      visibility: visible;
  }
}
@-moz-keyframes gwd-gen-3uwtgwdanimation_gwd-keyframes {
  0% {
      visibility: hidden;
      -moz-animation-timing-function: step-end;
  }
  29.7556% {
      visibility: inherit;
      -moz-animation-timing-function: step-end;
  }
  29.7778% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%) brightness(1000%);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(-84deg);
      -moz-animation-timing-function: linear;
  }
  30.5556% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%) brightness(100%);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  34% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%) brightness(100%);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  35.5556% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%) brightness(100%);
      -moz-transform: translate(-50%, -50%) translate3d(-420px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  72% {
      --gwd-hidden-css-filter: none;
      filter: opacity(1) brightness(1);
      -moz-transform: translate(-50%, -50%) translate3d(-420px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  73.1111% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%) brightness(1000%);
      -moz-transform: translate(-50%, -50%) translate3d(-420px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  74.2222% {
      --gwd-hidden-css-filter: none;
      filter: opacity(1) brightness(1);
      -moz-transform: translate(-50%, -50%) translate3d(-420px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  95.5556% {
      --gwd-hidden-css-filter: none;
      filter: opacity(1) brightness(1);
      -moz-transform: translate(-50%, -50%) translate3d(-420px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  99.9778% {
      --gwd-hidden-css-filter: none;
      filter: opacity(1) brightness(100%);
      -moz-transform: translate(-50%, -50%) translate3d(-420px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css-filter: none;
      filter: opacity(1) brightness(100%);
      -moz-transform: translate(-50%, -50%) translate3d(-420px, 0px, 0px) rotateY(0deg);
      visibility: visible;
  }
}
[data-gwd-group="Cards"] .gwd-gen-3uwtgwdanimation {
  animation: 9s linear 0s 1 normal forwards gwd-gen-3uwtgwdanimation_gwd-keyframes;
  -webkit-animation: 9s linear 0s 1 normal forwards gwd-gen-3uwtgwdanimation_gwd-keyframes;
  -moz-animation: 9s linear 0s 1 normal forwards gwd-gen-3uwtgwdanimation_gwd-keyframes;
}
[data-gwd-group="Cards"] .gwd-grp-ktf1.gwd-div-79ez {
  position: absolute;
  left: 50%;
  top: 50%;
  filter: opacity(100%) brightness(1000%);
  -webkit-filter: opacity(100%) brightness(1000%);
  transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(-84deg);
  -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(-84deg);
  -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(-84deg);
  visibility: inherit;
}
[data-gwd-group="Cards"] .gwd-grp-ktf1.gwd-div-rohz {
  position: absolute;
  left: 0px;
  top: 0px;
  transform: translate3d(0px, 0px, 349px);
  -webkit-transform: translate3d(0px, 0px, 349px);
  -moz-transform: translate3d(0px, 0px, 349px);
  filter: opacity(0%);
  -webkit-filter: opacity(0%);
  visibility: inherit;
}
[data-gwd-group="Card2"] {
  width: 140px;
  height: 256px;
}
[data-gwd-group="Card2"] .gwd-grp-15cb.gwd-img-11wh {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 182px;
  height: 298px;

  background: -webkit-linear-gradient(-90deg, rgb(58, 23, 67) 1%, rgb(24, 10, 28) 100%);
  background: -moz-linear-gradient(-90deg, rgb(58, 23, 67) 1%, rgb(24, 10, 28) 100%);
  background: linear-gradient(180deg, rgb(58, 23, 67) 1%, rgb(24, 10, 28) 100%);
  filter: opacity(100%);
  -webkit-filter: opacity(100%);
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
}
@keyframes gwd-gen-1rpngwdanimation_gwd-keyframes {
  0% {
      visibility: hidden;
      animation-timing-function: step-end;
      -webkit-animation-timing-function: step-end;
      -moz-animation-timing-function: step-end;
  }
  39.7556% {
      visibility: inherit;
      animation-timing-function: step-end;
      -webkit-animation-timing-function: step-end;
      -moz-animation-timing-function: step-end;
  }
  39.7778% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%) brightness(1000%);
      -webkit-filter: opacity(100%) brightness(1000%);
      transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(-84deg);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(-84deg);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(-84deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  40.5556% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%) brightness(100%);
      -webkit-filter: opacity(100%) brightness(100%);
      transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  44% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%) brightness(100%);
      -webkit-filter: opacity(100%) brightness(100%);
      transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  45.5556% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%) brightness(100%);
      -webkit-filter: opacity(100%) brightness(100%);
      transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  72% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%) brightness(100%);
      -webkit-filter: opacity(100%) brightness(100%);
      transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  73.1111% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(1) brightness(1);
      -webkit-filter: opacity(1) brightness(1);
      transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  74.2222% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%) brightness(1000%);
      -webkit-filter: opacity(100%) brightness(1000%);
      transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  75.3333% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(1) brightness(1);
      -webkit-filter: opacity(1) brightness(1);
      transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  95.5556% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(1) brightness(1);
      -webkit-filter: opacity(1) brightness(1);
      transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  99.9778% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(1) brightness(100%);
      -webkit-filter: opacity(1) brightness(100%);
      transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(1) brightness(100%);
      -webkit-filter: opacity(1) brightness(100%);
      transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      visibility: visible;
  }
}
@-webkit-keyframes gwd-gen-1rpngwdanimation_gwd-keyframes {
  0% {
      visibility: hidden;
      -webkit-animation-timing-function: step-end;
  }
  39.7556% {
      visibility: inherit;
      -webkit-animation-timing-function: step-end;
  }
  39.7778% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%) brightness(1000%);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(-84deg);
      -webkit-animation-timing-function: linear;
  }
  40.5556% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%) brightness(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  44% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%) brightness(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  45.5556% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%) brightness(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  72% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%) brightness(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  73.1111% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(1) brightness(1);
      -webkit-transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  74.2222% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%) brightness(1000%);
      -webkit-transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  75.3333% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(1) brightness(1);
      -webkit-transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  95.5556% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(1) brightness(1);
      -webkit-transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  99.9778% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(1) brightness(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(1) brightness(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      visibility: visible;
  }
}
@-moz-keyframes gwd-gen-1rpngwdanimation_gwd-keyframes {
  0% {
      visibility: hidden;
      -moz-animation-timing-function: step-end;
  }
  39.7556% {
      visibility: inherit;
      -moz-animation-timing-function: step-end;
  }
  39.7778% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%) brightness(1000%);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(-84deg);
      -moz-animation-timing-function: linear;
  }
  40.5556% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%) brightness(100%);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  44% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%) brightness(100%);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  45.5556% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%) brightness(100%);
      -moz-transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  72% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%) brightness(100%);
      -moz-transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  73.1111% {
      --gwd-hidden-css-filter: none;
      filter: opacity(1) brightness(1);
      -moz-transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  74.2222% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%) brightness(1000%);
      -moz-transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  75.3333% {
      --gwd-hidden-css-filter: none;
      filter: opacity(1) brightness(1);
      -moz-transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  95.5556% {
      --gwd-hidden-css-filter: none;
      filter: opacity(1) brightness(1);
      -moz-transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  99.9778% {
      --gwd-hidden-css-filter: none;
      filter: opacity(1) brightness(100%);
      -moz-transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css-filter: none;
      filter: opacity(1) brightness(100%);
      -moz-transform: translate(-50%, -50%) translate3d(-210px, 0px, 0px) rotateY(0deg);
      visibility: visible;
  }
}
[data-gwd-group="Cards"] .gwd-gen-1rpngwdanimation {
  animation: 9s linear 0s 1 normal forwards gwd-gen-1rpngwdanimation_gwd-keyframes;
  -webkit-animation: 9s linear 0s 1 normal forwards gwd-gen-1rpngwdanimation_gwd-keyframes;
  -moz-animation: 9s linear 0s 1 normal forwards gwd-gen-1rpngwdanimation_gwd-keyframes;
}
[data-gwd-group="Cards"] .gwd-grp-ktf1.gwd-div-12op {
  position: absolute;
  left: 50%;
  top: 50%;
  filter: opacity(100%) brightness(1000%);
  -webkit-filter: opacity(100%) brightness(1000%);
  transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(-84deg);
  -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(-84deg);
  -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(-84deg);
  visibility: inherit;
}
@keyframes gwd-gen-1f5kgwdanimation_gwd-keyframes {
  0% {
      visibility: hidden;
      animation-timing-function: step-end;
      -webkit-animation-timing-function: step-end;
      -moz-animation-timing-function: step-end;
  }
  80.4737% {
      visibility: inherit;
      animation-timing-function: step-end;
      -webkit-animation-timing-function: step-end;
      -moz-animation-timing-function: step-end;
  }
  80.5263% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(0%);
      -webkit-filter: opacity(0%);
      transform: translate3d(0px, 0px, 349px) rotateY(0deg);
      -webkit-transform: translate3d(0px, 0px, 349px) rotateY(0deg);
      -moz-transform: translate3d(0px, 0px, 349px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  86.8421% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  91.8421% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  92.1053% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  93.9474% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(1);
      -webkit-filter: opacity(1);
      transform: translate3d(0px, 0px, 0px) rotateY(84deg);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(84deg);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(84deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  94.2105% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(0%);
      -webkit-filter: opacity(0%);
      transform: translate3d(0px, 0px, 0px) rotateY(96deg);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(96deg);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(96deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  96.0526% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(0%);
      -webkit-filter: opacity(0%);
      transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(0%);
      -webkit-filter: opacity(0%);
      transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      visibility: hidden;
  }
}
@-webkit-keyframes gwd-gen-1f5kgwdanimation_gwd-keyframes {
  0% {
      visibility: hidden;
      -webkit-animation-timing-function: step-end;
  }
  80.4737% {
      visibility: inherit;
      -webkit-animation-timing-function: step-end;
  }
  80.5263% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(0%);
      -webkit-transform: translate3d(0px, 0px, 349px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  86.8421% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  91.8421% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  92.1053% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  93.9474% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(1);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(84deg);
      -webkit-animation-timing-function: linear;
  }
  94.2105% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(0%);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(96deg);
      -webkit-animation-timing-function: linear;
  }
  96.0526% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(0%);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      -webkit-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(0%);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      visibility: hidden;
  }
}
@-moz-keyframes gwd-gen-1f5kgwdanimation_gwd-keyframes {
  0% {
      visibility: hidden;
      -moz-animation-timing-function: step-end;
  }
  80.4737% {
      visibility: inherit;
      -moz-animation-timing-function: step-end;
  }
  80.5263% {
      --gwd-hidden-css-filter: none;
      filter: opacity(0%);
      -moz-transform: translate3d(0px, 0px, 349px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  86.8421% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  91.8421% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  92.1053% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  93.9474% {
      --gwd-hidden-css-filter: none;
      filter: opacity(1);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(84deg);
      -moz-animation-timing-function: linear;
  }
  94.2105% {
      --gwd-hidden-css-filter: none;
      filter: opacity(0%);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(96deg);
      -moz-animation-timing-function: linear;
  }
  96.0526% {
      --gwd-hidden-css-filter: none;
      filter: opacity(0%);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      -moz-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css-filter: none;
      filter: opacity(0%);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      visibility: hidden;
  }
}
[data-gwd-group="Cards"] .gwd-gen-1f5kgwdanimation {
  animation: 3.8s linear 0s 1 normal forwards gwd-gen-1f5kgwdanimation_gwd-keyframes;
  -webkit-animation: 3.8s linear 0s 1 normal forwards gwd-gen-1f5kgwdanimation_gwd-keyframes;
  -moz-animation: 3.8s linear 0s 1 normal forwards gwd-gen-1f5kgwdanimation_gwd-keyframes;
}
@keyframes gwd-gen-1dbggwdanimation_gwd-keyframes {
  0% {
      visibility: hidden;
      animation-timing-function: step-end;
      -webkit-animation-timing-function: step-end;
      -moz-animation-timing-function: step-end;
  }
  71.3571% {
      visibility: inherit;
      animation-timing-function: step-end;
      -webkit-animation-timing-function: step-end;
      -moz-animation-timing-function: step-end;
  }
  71.4286% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(0%);
      -webkit-filter: opacity(0%);
      transform: translate3d(0px, 0px, 349px) rotateY(0deg);
      -webkit-transform: translate3d(0px, 0px, 349px) rotateY(0deg);
      -moz-transform: translate3d(0px, 0px, 349px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  85.7143% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  92.5% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  92.8571% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  95.3571% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(1);
      -webkit-filter: opacity(1);
      transform: translate3d(0px, 0px, 0px) rotateY(84deg);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(84deg);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(84deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  95.7143% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(0%);
      -webkit-filter: opacity(0%);
      transform: translate3d(0px, 0px, 0px) rotateY(96deg);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(96deg);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(96deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  98.2143% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(0%);
      -webkit-filter: opacity(0%);
      transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(0%);
      -webkit-filter: opacity(0%);
      transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      visibility: hidden;
  }
}
@-webkit-keyframes gwd-gen-1dbggwdanimation_gwd-keyframes {
  0% {
      visibility: hidden;
      -webkit-animation-timing-function: step-end;
  }
  71.3571% {
      visibility: inherit;
      -webkit-animation-timing-function: step-end;
  }
  71.4286% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(0%);
      -webkit-transform: translate3d(0px, 0px, 349px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  85.7143% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  92.5% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  92.8571% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  95.3571% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(1);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(84deg);
      -webkit-animation-timing-function: linear;
  }
  95.7143% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(0%);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(96deg);
      -webkit-animation-timing-function: linear;
  }
  98.2143% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(0%);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      -webkit-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(0%);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      visibility: hidden;
  }
}
@-moz-keyframes gwd-gen-1dbggwdanimation_gwd-keyframes {
  0% {
      visibility: hidden;
      -moz-animation-timing-function: step-end;
  }
  71.3571% {
      visibility: inherit;
      -moz-animation-timing-function: step-end;
  }
  71.4286% {
      --gwd-hidden-css-filter: none;
      filter: opacity(0%);
      -moz-transform: translate3d(0px, 0px, 349px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  85.7143% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  92.5% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  92.8571% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  95.3571% {
      --gwd-hidden-css-filter: none;
      filter: opacity(1);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(84deg);
      -moz-animation-timing-function: linear;
  }
  95.7143% {
      --gwd-hidden-css-filter: none;
      filter: opacity(0%);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(96deg);
      -moz-animation-timing-function: linear;
  }
  98.2143% {
      --gwd-hidden-css-filter: none;
      filter: opacity(0%);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      -moz-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css-filter: none;
      filter: opacity(0%);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      visibility: hidden;
  }
}
[data-gwd-group="Cards"] .gwd-gen-1dbggwdanimation {
  animation: 2.8s linear 0s 1 normal forwards gwd-gen-1dbggwdanimation_gwd-keyframes;
  -webkit-animation: 2.8s linear 0s 1 normal forwards gwd-gen-1dbggwdanimation_gwd-keyframes;
  -moz-animation: 2.8s linear 0s 1 normal forwards gwd-gen-1dbggwdanimation_gwd-keyframes;
}
[data-gwd-group="Cards"] .gwd-grp-ktf1.gwd-div-bzsy {
  position: absolute;
  left: 0px;
  top: 0px;
  transform: translate3d(0px, 0px, 349px);
  -webkit-transform: translate3d(0px, 0px, 349px);
  -moz-transform: translate3d(0px, 0px, 349px);
  filter: opacity(0%);
  -webkit-filter: opacity(0%);
  visibility: inherit;
}
@keyframes gwd-gen-1f54gwdanimation_gwd-keyframes {
  0% {
      visibility: hidden;
      animation-timing-function: step-end;
      -webkit-animation-timing-function: step-end;
      -moz-animation-timing-function: step-end;
  }
  86.0435% {
      visibility: inherit;
      animation-timing-function: step-end;
      -webkit-animation-timing-function: step-end;
      -moz-animation-timing-function: step-end;
  }
  86.087% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(0%);
      -webkit-filter: opacity(0%);
      transform: translate3d(0px, 0px, 349px) rotateY(0deg);
      -webkit-transform: translate3d(0px, 0px, 349px) rotateY(0deg);
      -moz-transform: translate3d(0px, 0px, 349px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  91.3043% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  95.4348% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  95.6522% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  97.1739% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(1);
      -webkit-filter: opacity(1);
      transform: translate3d(0px, 0px, 0px) rotateY(84deg);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(84deg);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(84deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  97.3913% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(0%);
      -webkit-filter: opacity(0%);
      transform: translate3d(0px, 0px, 0px) rotateY(96deg);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(96deg);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(96deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  98.913% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(0%);
      -webkit-filter: opacity(0%);
      transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(0%);
      -webkit-filter: opacity(0%);
      transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      visibility: hidden;
  }
}
@-webkit-keyframes gwd-gen-1f54gwdanimation_gwd-keyframes {
  0% {
      visibility: hidden;
      -webkit-animation-timing-function: step-end;
  }
  86.0435% {
      visibility: inherit;
      -webkit-animation-timing-function: step-end;
  }
  86.087% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(0%);
      -webkit-transform: translate3d(0px, 0px, 349px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  91.3043% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  95.4348% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  95.6522% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  97.1739% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(1);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(84deg);
      -webkit-animation-timing-function: linear;
  }
  97.3913% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(0%);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(96deg);
      -webkit-animation-timing-function: linear;
  }
  98.913% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(0%);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      -webkit-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(0%);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      visibility: hidden;
  }
}
@-moz-keyframes gwd-gen-1f54gwdanimation_gwd-keyframes {
  0% {
      visibility: hidden;
      -moz-animation-timing-function: step-end;
  }
  86.0435% {
      visibility: inherit;
      -moz-animation-timing-function: step-end;
  }
  86.087% {
      --gwd-hidden-css-filter: none;
      filter: opacity(0%);
      -moz-transform: translate3d(0px, 0px, 349px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  91.3043% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  95.4348% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  95.6522% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  97.1739% {
      --gwd-hidden-css-filter: none;
      filter: opacity(1);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(84deg);
      -moz-animation-timing-function: linear;
  }
  97.3913% {
      --gwd-hidden-css-filter: none;
      filter: opacity(0%);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(96deg);
      -moz-animation-timing-function: linear;
  }
  98.913% {
      --gwd-hidden-css-filter: none;
      filter: opacity(0%);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      -moz-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css-filter: none;
      filter: opacity(0%);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      visibility: hidden;
  }
}
[data-gwd-group="Cards"] .gwd-gen-1f54gwdanimation {
  animation: 4.6s linear 0s 1 normal forwards gwd-gen-1f54gwdanimation_gwd-keyframes;
  -webkit-animation: 4.6s linear 0s 1 normal forwards gwd-gen-1f54gwdanimation_gwd-keyframes;
  -moz-animation: 4.6s linear 0s 1 normal forwards gwd-gen-1f54gwdanimation_gwd-keyframes;
}
[data-gwd-group="Cards"] .gwd-grp-ktf1.gwd-div-104y {
  position: absolute;
  left: 0px;
  top: 0px;
  transform: translate3d(0px, 0px, 349px);
  -webkit-transform: translate3d(0px, 0px, 349px);
  -moz-transform: translate3d(0px, 0px, 349px);
  filter: opacity(0%);
  -webkit-filter: opacity(0%);
  visibility: inherit;
}
@keyframes gwd-gen-7aldgwdanimation_gwd-keyframes {
  0% {
      visibility: hidden;
      animation-timing-function: step-end;
      -webkit-animation-timing-function: step-end;
      -moz-animation-timing-function: step-end;
  }
  59.7556% {
      visibility: inherit;
      animation-timing-function: step-end;
      -webkit-animation-timing-function: step-end;
      -moz-animation-timing-function: step-end;
  }
  59.7778% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%) brightness(1000%);
      -webkit-filter: opacity(100%) brightness(1000%);
      transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(-84deg);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(-84deg);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(-84deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  60.5556% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%) brightness(100%);
      -webkit-filter: opacity(100%) brightness(100%);
      transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  64% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%) brightness(100%);
      -webkit-filter: opacity(100%) brightness(100%);
      transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  65.5556% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%) brightness(100%);
      -webkit-filter: opacity(100%) brightness(100%);
      transform: translate(-50%, -50%) translate3d(210px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(210px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(210px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  75.3333% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(1) brightness(1);
      -webkit-filter: opacity(1) brightness(1);
      transform: translate(-50%, -50%) translate3d(210px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(210px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(210px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  76.4444% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%) brightness(1000%);
      -webkit-filter: opacity(100%) brightness(1000%);
      transform: translate(-50%, -50%) translate3d(210px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(210px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(210px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  77.5556% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(1) brightness(1);
      -webkit-filter: opacity(1) brightness(1);
      transform: translate(-50%, -50%) translate3d(210px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(210px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(210px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  95.5556% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(1) brightness(1);
      -webkit-filter: opacity(1) brightness(1);
      transform: translate(-50%, -50%) translate3d(210px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(210px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(210px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  99.9778% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(1) brightness(100%);
      -webkit-filter: opacity(1) brightness(100%);
      transform: translate(-50%, -50%) translate3d(210px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(210px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(210px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(1) brightness(100%);
      -webkit-filter: opacity(1) brightness(100%);
      transform: translate(-50%, -50%) translate3d(210px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(210px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(210px, 0px, 0px) rotateY(0deg);
      visibility: visible;
  }
}
@-webkit-keyframes gwd-gen-7aldgwdanimation_gwd-keyframes {
  0% {
      visibility: hidden;
      -webkit-animation-timing-function: step-end;
  }
  59.7556% {
      visibility: inherit;
      -webkit-animation-timing-function: step-end;
  }
  59.7778% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%) brightness(1000%);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(-84deg);
      -webkit-animation-timing-function: linear;
  }
  60.5556% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%) brightness(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  64% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%) brightness(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  65.5556% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%) brightness(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(210px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  75.3333% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(1) brightness(1);
      -webkit-transform: translate(-50%, -50%) translate3d(210px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  76.4444% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%) brightness(1000%);
      -webkit-transform: translate(-50%, -50%) translate3d(210px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  77.5556% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(1) brightness(1);
      -webkit-transform: translate(-50%, -50%) translate3d(210px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  95.5556% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(1) brightness(1);
      -webkit-transform: translate(-50%, -50%) translate3d(210px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  99.9778% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(1) brightness(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(210px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(1) brightness(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(210px, 0px, 0px) rotateY(0deg);
      visibility: visible;
  }
}
@-moz-keyframes gwd-gen-7aldgwdanimation_gwd-keyframes {
  0% {
      visibility: hidden;
      -moz-animation-timing-function: step-end;
  }
  59.7556% {
      visibility: inherit;
      -moz-animation-timing-function: step-end;
  }
  59.7778% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%) brightness(1000%);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(-84deg);
      -moz-animation-timing-function: linear;
  }
  60.5556% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%) brightness(100%);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  64% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%) brightness(100%);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  65.5556% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%) brightness(100%);
      -moz-transform: translate(-50%, -50%) translate3d(210px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  75.3333% {
      --gwd-hidden-css-filter: none;
      filter: opacity(1) brightness(1);
      -moz-transform: translate(-50%, -50%) translate3d(210px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  76.4444% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%) brightness(1000%);
      -moz-transform: translate(-50%, -50%) translate3d(210px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  77.5556% {
      --gwd-hidden-css-filter: none;
      filter: opacity(1) brightness(1);
      -moz-transform: translate(-50%, -50%) translate3d(210px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  95.5556% {
      --gwd-hidden-css-filter: none;
      filter: opacity(1) brightness(1);
      -moz-transform: translate(-50%, -50%) translate3d(210px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  99.9778% {
      --gwd-hidden-css-filter: none;
      filter: opacity(1) brightness(100%);
      -moz-transform: translate(-50%, -50%) translate3d(210px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css-filter: none;
      filter: opacity(1) brightness(100%);
      -moz-transform: translate(-50%, -50%) translate3d(210px, 0px, 0px) rotateY(0deg);
      visibility: visible;
  }
}
[data-gwd-group="Cards"] .gwd-gen-7aldgwdanimation {
  animation: 9s linear 0s 1 normal forwards gwd-gen-7aldgwdanimation_gwd-keyframes;
  -webkit-animation: 9s linear 0s 1 normal forwards gwd-gen-7aldgwdanimation_gwd-keyframes;
  -moz-animation: 9s linear 0s 1 normal forwards gwd-gen-7aldgwdanimation_gwd-keyframes;
}
[data-gwd-group="Cards"] .gwd-grp-ktf1.gwd-div-1wju {
  position: absolute;
  left: 50%;
  top: 50%;
  filter: opacity(100%) brightness(1000%);
  -webkit-filter: opacity(100%) brightness(1000%);
  transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(-84deg);
  -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(-84deg);
  -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(-84deg);
  visibility: inherit;
}
@keyframes gwd-gen-nu36gwdanimation_gwd-keyframes {
  0% {
      visibility: hidden;
      animation-timing-function: step-end;
      -webkit-animation-timing-function: step-end;
      -moz-animation-timing-function: step-end;
  }
  89.9688% {
      visibility: inherit;
      animation-timing-function: step-end;
      -webkit-animation-timing-function: step-end;
      -moz-animation-timing-function: step-end;
  }
  90% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(0%);
      -webkit-filter: opacity(0%);
      transform: translate3d(0px, 0px, 349px) rotateY(0deg);
      -webkit-transform: translate3d(0px, 0px, 349px) rotateY(0deg);
      -moz-transform: translate3d(0px, 0px, 349px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  93.75% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  96.7188% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  96.875% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  97.9688% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(1);
      -webkit-filter: opacity(1);
      transform: translate3d(0px, 0px, 0px) rotateY(84deg);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(84deg);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(84deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  98.125% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(0%);
      -webkit-filter: opacity(0%);
      transform: translate3d(0px, 0px, 0px) rotateY(96deg);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(96deg);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(96deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  99.2188% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(0%);
      -webkit-filter: opacity(0%);
      transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(0%);
      -webkit-filter: opacity(0%);
      transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      visibility: hidden;
  }
}
@-webkit-keyframes gwd-gen-nu36gwdanimation_gwd-keyframes {
  0% {
      visibility: hidden;
      -webkit-animation-timing-function: step-end;
  }
  89.9688% {
      visibility: inherit;
      -webkit-animation-timing-function: step-end;
  }
  90% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(0%);
      -webkit-transform: translate3d(0px, 0px, 349px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  93.75% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  96.7188% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  96.875% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  97.9688% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(1);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(84deg);
      -webkit-animation-timing-function: linear;
  }
  98.125% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(0%);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(96deg);
      -webkit-animation-timing-function: linear;
  }
  99.2188% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(0%);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      -webkit-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(0%);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      visibility: hidden;
  }
}
@-moz-keyframes gwd-gen-nu36gwdanimation_gwd-keyframes {
  0% {
      visibility: hidden;
      -moz-animation-timing-function: step-end;
  }
  89.9688% {
      visibility: inherit;
      -moz-animation-timing-function: step-end;
  }
  90% {
      --gwd-hidden-css-filter: none;
      filter: opacity(0%);
      -moz-transform: translate3d(0px, 0px, 349px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  93.75% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  96.7188% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  96.875% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  97.9688% {
      --gwd-hidden-css-filter: none;
      filter: opacity(1);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(84deg);
      -moz-animation-timing-function: linear;
  }
  98.125% {
      --gwd-hidden-css-filter: none;
      filter: opacity(0%);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(96deg);
      -moz-animation-timing-function: linear;
  }
  99.2188% {
      --gwd-hidden-css-filter: none;
      filter: opacity(0%);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      -moz-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css-filter: none;
      filter: opacity(0%);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      visibility: hidden;
  }
}
[data-gwd-group="Cards"] .gwd-gen-nu36gwdanimation {
  animation: 6.4s linear 0s 1 normal forwards gwd-gen-nu36gwdanimation_gwd-keyframes;
  -webkit-animation: 6.4s linear 0s 1 normal forwards gwd-gen-nu36gwdanimation_gwd-keyframes;
  -moz-animation: 6.4s linear 0s 1 normal forwards gwd-gen-nu36gwdanimation_gwd-keyframes;
}
[data-gwd-group="Cards"] .gwd-grp-ktf1.gwd-div-rgm1 {
  position: absolute;
  left: 0px;
  top: 0px;
  transform: translate3d(0px, 0px, 349px);
  -webkit-transform: translate3d(0px, 0px, 349px);
  -moz-transform: translate3d(0px, 0px, 349px);
  filter: opacity(0%);
  -webkit-filter: opacity(0%);
  visibility: inherit;
}
@keyframes gwd-gen-qvoggwdanimation_gwd-keyframes {
  0% {
      visibility: hidden;
      animation-timing-function: step-end;
      -webkit-animation-timing-function: step-end;
      -moz-animation-timing-function: step-end;
  }
  49.7556% {
      visibility: inherit;
      animation-timing-function: step-end;
      -webkit-animation-timing-function: step-end;
      -moz-animation-timing-function: step-end;
  }
  49.7778% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%) brightness(1000%);
      -webkit-filter: opacity(100%) brightness(1000%);
      transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(-84deg);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(-84deg);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(-84deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  50.5556% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%) brightness(100%);
      -webkit-filter: opacity(100%) brightness(100%);
      transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  54% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%) brightness(100%);
      -webkit-filter: opacity(100%) brightness(100%);
      transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  55.5556% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%) brightness(100%);
      -webkit-filter: opacity(100%) brightness(100%);
      transform: translate(-50%, -50%) translate3d(420px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(420px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(420px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  76.4444% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(1) brightness(1);
      -webkit-filter: opacity(1) brightness(1);
      transform: translate(-50%, -50%) translate3d(420px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(420px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(420px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  77.5556% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%) brightness(1000%);
      -webkit-filter: opacity(100%) brightness(1000%);
      transform: translate(-50%, -50%) translate3d(420px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(420px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(420px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  78.6667% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(1) brightness(1);
      -webkit-filter: opacity(1) brightness(1);
      transform: translate(-50%, -50%) translate3d(420px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(420px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(420px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  95.5556% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(1) brightness(1);
      -webkit-filter: opacity(1) brightness(1);
      transform: translate(-50%, -50%) translate3d(420px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(420px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(420px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  99.9778% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(1) brightness(100%);
      -webkit-filter: opacity(1) brightness(100%);
      transform: translate(-50%, -50%) translate3d(420px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(420px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(420px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(1) brightness(100%);
      -webkit-filter: opacity(1) brightness(100%);
      transform: translate(-50%, -50%) translate3d(420px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(420px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(420px, 0px, 0px) rotateY(0deg);
      visibility: visible;
  }
}
@-webkit-keyframes gwd-gen-qvoggwdanimation_gwd-keyframes {
  0% {
      visibility: hidden;
      -webkit-animation-timing-function: step-end;
  }
  49.7556% {
      visibility: inherit;
      -webkit-animation-timing-function: step-end;
  }
  49.7778% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%) brightness(1000%);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(-84deg);
      -webkit-animation-timing-function: linear;
  }
  50.5556% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%) brightness(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  54% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%) brightness(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  55.5556% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%) brightness(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(420px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  76.4444% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(1) brightness(1);
      -webkit-transform: translate(-50%, -50%) translate3d(420px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  77.5556% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%) brightness(1000%);
      -webkit-transform: translate(-50%, -50%) translate3d(420px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  78.6667% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(1) brightness(1);
      -webkit-transform: translate(-50%, -50%) translate3d(420px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  95.5556% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(1) brightness(1);
      -webkit-transform: translate(-50%, -50%) translate3d(420px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  99.9778% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(1) brightness(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(420px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(1) brightness(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(420px, 0px, 0px) rotateY(0deg);
      visibility: visible;
  }
}
@-moz-keyframes gwd-gen-qvoggwdanimation_gwd-keyframes {
  0% {
      visibility: hidden;
      -moz-animation-timing-function: step-end;
  }
  49.7556% {
      visibility: inherit;
      -moz-animation-timing-function: step-end;
  }
  49.7778% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%) brightness(1000%);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(-84deg);
      -moz-animation-timing-function: linear;
  }
  50.5556% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%) brightness(100%);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  54% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%) brightness(100%);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  55.5556% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%) brightness(100%);
      -moz-transform: translate(-50%, -50%) translate3d(420px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  76.4444% {
      --gwd-hidden-css-filter: none;
      filter: opacity(1) brightness(1);
      -moz-transform: translate(-50%, -50%) translate3d(420px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  77.5556% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%) brightness(1000%);
      -moz-transform: translate(-50%, -50%) translate3d(420px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  78.6667% {
      --gwd-hidden-css-filter: none;
      filter: opacity(1) brightness(1);
      -moz-transform: translate(-50%, -50%) translate3d(420px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  95.5556% {
      --gwd-hidden-css-filter: none;
      filter: opacity(1) brightness(1);
      -moz-transform: translate(-50%, -50%) translate3d(420px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  99.9778% {
      --gwd-hidden-css-filter: none;
      filter: opacity(1) brightness(100%);
      -moz-transform: translate(-50%, -50%) translate3d(420px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css-filter: none;
      filter: opacity(1) brightness(100%);
      -moz-transform: translate(-50%, -50%) translate3d(420px, 0px, 0px) rotateY(0deg);
      visibility: visible;
  }
}
[data-gwd-group="Cards"] .gwd-gen-qvoggwdanimation {
  animation: 9s linear 0s 1 normal forwards gwd-gen-qvoggwdanimation_gwd-keyframes;
  -webkit-animation: 9s linear 0s 1 normal forwards gwd-gen-qvoggwdanimation_gwd-keyframes;
  -moz-animation: 9s linear 0s 1 normal forwards gwd-gen-qvoggwdanimation_gwd-keyframes;
}
[data-gwd-group="Cards"] .gwd-grp-ktf1.gwd-div-er2s {
  position: absolute;
  left: 50%;
  top: 50%;
  filter: opacity(100%) brightness(1000%);
  -webkit-filter: opacity(100%) brightness(1000%);
  transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(-84deg);
  -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(-84deg);
  -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(-84deg);
  visibility: inherit;
}
@keyframes gwd-gen-gaofgwdanimation_gwd-keyframes {
  0% {
      visibility: hidden;
      animation-timing-function: step-end;
      -webkit-animation-timing-function: step-end;
      -moz-animation-timing-function: step-end;
  }
  86.75% {
      visibility: inherit;
      animation-timing-function: step-end;
      -webkit-animation-timing-function: step-end;
      -moz-animation-timing-function: step-end;
  }
  86.7857% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(0%);
      -webkit-filter: opacity(0%);
      transform: translate3d(0px, 0px, 349px) rotateY(0deg);
      -webkit-transform: translate3d(0px, 0px, 349px) rotateY(0deg);
      -moz-transform: translate3d(0px, 0px, 349px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  91.0714% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  94.4643% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  94.6429% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  95.8929% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(1);
      -webkit-filter: opacity(1);
      transform: translate3d(0px, 0px, 0px) rotateY(84deg);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(84deg);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(84deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  96.0714% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(0%);
      -webkit-filter: opacity(0%);
      transform: translate3d(0px, 0px, 0px) rotateY(96deg);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(96deg);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(96deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  97.3214% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(0%);
      -webkit-filter: opacity(0%);
      transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(0%);
      -webkit-filter: opacity(0%);
      transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      visibility: hidden;
  }
}
@-webkit-keyframes gwd-gen-gaofgwdanimation_gwd-keyframes {
  0% {
      visibility: hidden;
      -webkit-animation-timing-function: step-end;
  }
  86.75% {
      visibility: inherit;
      -webkit-animation-timing-function: step-end;
  }
  86.7857% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(0%);
      -webkit-transform: translate3d(0px, 0px, 349px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  91.0714% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  94.4643% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  94.6429% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  95.8929% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(1);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(84deg);
      -webkit-animation-timing-function: linear;
  }
  96.0714% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(0%);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(96deg);
      -webkit-animation-timing-function: linear;
  }
  97.3214% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(0%);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      -webkit-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(0%);
      -webkit-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      visibility: hidden;
  }
}
@-moz-keyframes gwd-gen-gaofgwdanimation_gwd-keyframes {
  0% {
      visibility: hidden;
      -moz-animation-timing-function: step-end;
  }
  86.75% {
      visibility: inherit;
      -moz-animation-timing-function: step-end;
  }
  86.7857% {
      --gwd-hidden-css-filter: none;
      filter: opacity(0%);
      -moz-transform: translate3d(0px, 0px, 349px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  91.0714% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  94.4643% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  94.6429% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  95.8929% {
      --gwd-hidden-css-filter: none;
      filter: opacity(1);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(84deg);
      -moz-animation-timing-function: linear;
  }
  96.0714% {
      --gwd-hidden-css-filter: none;
      filter: opacity(0%);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(96deg);
      -moz-animation-timing-function: linear;
  }
  97.3214% {
      --gwd-hidden-css-filter: none;
      filter: opacity(0%);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      -moz-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css-filter: none;
      filter: opacity(0%);
      -moz-transform: translate3d(0px, 0px, 0px) rotateY(180deg);
      visibility: hidden;
  }
}
[data-gwd-group="Cards"] .gwd-gen-gaofgwdanimation {
  animation: 5.6s linear 0s 1 normal forwards gwd-gen-gaofgwdanimation_gwd-keyframes;
  -webkit-animation: 5.6s linear 0s 1 normal forwards gwd-gen-gaofgwdanimation_gwd-keyframes;
  -moz-animation: 5.6s linear 0s 1 normal forwards gwd-gen-gaofgwdanimation_gwd-keyframes;
}
[data-gwd-group="Cards"] .gwd-grp-ktf1.gwd-div-jj2w {
  position: absolute;
  left: 0px;
  top: 0px;
  transform: translate3d(0px, 0px, 349px);
  -webkit-transform: translate3d(0px, 0px, 349px);
  -moz-transform: translate3d(0px, 0px, 349px);
  filter: opacity(0%);
  -webkit-filter: opacity(0%);
  visibility: inherit;
}
@keyframes gwd-gen-qkz5gwdanimation_gwd-keyframes {
  0% {
      visibility: hidden;
      animation-timing-function: step-end;
      -webkit-animation-timing-function: step-end;
      -moz-animation-timing-function: step-end;
  }
  69.7556% {
      visibility: inherit;
      animation-timing-function: step-end;
      -webkit-animation-timing-function: step-end;
      -moz-animation-timing-function: step-end;
  }
  69.7778% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%) brightness(1000%);
      -webkit-filter: opacity(100%) brightness(1000%);
      transform: translate(-50%, -50%) rotateY(-84deg);
      -webkit-transform: translate(-50%, -50%) rotateY(-84deg);
      -moz-transform: translate(-50%, -50%) rotateY(-84deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  70.5556% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%) brightness(100%);
      -webkit-filter: opacity(100%) brightness(100%);
      transform: translate(-50%, -50%) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  74.2222% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%) brightness(100%);
      -webkit-filter: opacity(100%) brightness(100%);
      transform: translate(-50%, -50%) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  75.3333% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%) brightness(1000%);
      -webkit-filter: opacity(100%) brightness(1000%);
      transform: translate(-50%, -50%) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  76.4444% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(1) brightness(1);
      -webkit-filter: opacity(1) brightness(1);
      transform: translate(-50%, -50%) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  95.5556% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(1) brightness(1);
      -webkit-filter: opacity(1) brightness(1);
      transform: translate(-50%, -50%) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  99.9778% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(1) brightness(100%);
      -webkit-filter: opacity(1) brightness(100%);
      transform: translate(-50%, -50%) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) rotateY(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(1) brightness(100%);
      -webkit-filter: opacity(1) brightness(100%);
      transform: translate(-50%, -50%) rotateY(0deg);
      -webkit-transform: translate(-50%, -50%) rotateY(0deg);
      -moz-transform: translate(-50%, -50%) rotateY(0deg);
      visibility: visible;
  }
}
@-webkit-keyframes gwd-gen-qkz5gwdanimation_gwd-keyframes {
  0% {
      visibility: hidden;
      -webkit-animation-timing-function: step-end;
  }
  69.7556% {
      visibility: inherit;
      -webkit-animation-timing-function: step-end;
  }
  69.7778% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%) brightness(1000%);
      -webkit-transform: translate(-50%, -50%) rotateY(-84deg);
      -webkit-animation-timing-function: linear;
  }
  70.5556% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%) brightness(100%);
      -webkit-transform: translate(-50%, -50%) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  74.2222% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%) brightness(100%);
      -webkit-transform: translate(-50%, -50%) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  75.3333% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%) brightness(1000%);
      -webkit-transform: translate(-50%, -50%) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  76.4444% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(1) brightness(1);
      -webkit-transform: translate(-50%, -50%) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  95.5556% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(1) brightness(1);
      -webkit-transform: translate(-50%, -50%) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  99.9778% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(1) brightness(100%);
      -webkit-transform: translate(-50%, -50%) rotateY(0deg);
      -webkit-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(1) brightness(100%);
      -webkit-transform: translate(-50%, -50%) rotateY(0deg);
      visibility: visible;
  }
}
@-moz-keyframes gwd-gen-qkz5gwdanimation_gwd-keyframes {
  0% {
      visibility: hidden;
      -moz-animation-timing-function: step-end;
  }
  69.7556% {
      visibility: inherit;
      -moz-animation-timing-function: step-end;
  }
  69.7778% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%) brightness(1000%);
      -moz-transform: translate(-50%, -50%) rotateY(-84deg);
      -moz-animation-timing-function: linear;
  }
  70.5556% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%) brightness(100%);
      -moz-transform: translate(-50%, -50%) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  74.2222% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%) brightness(100%);
      -moz-transform: translate(-50%, -50%) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  75.3333% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%) brightness(1000%);
      -moz-transform: translate(-50%, -50%) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  76.4444% {
      --gwd-hidden-css-filter: none;
      filter: opacity(1) brightness(1);
      -moz-transform: translate(-50%, -50%) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  95.5556% {
      --gwd-hidden-css-filter: none;
      filter: opacity(1) brightness(1);
      -moz-transform: translate(-50%, -50%) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  99.9778% {
      --gwd-hidden-css-filter: none;
      filter: opacity(1) brightness(100%);
      -moz-transform: translate(-50%, -50%) rotateY(0deg);
      -moz-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css-filter: none;
      filter: opacity(1) brightness(100%);
      -moz-transform: translate(-50%, -50%) rotateY(0deg);
      visibility: visible;
  }
}
[data-gwd-group="Cards"] .gwd-gen-qkz5gwdanimation {
  animation: 9s linear 0s 1 normal forwards gwd-gen-qkz5gwdanimation_gwd-keyframes;
  -webkit-animation: 9s linear 0s 1 normal forwards gwd-gen-qkz5gwdanimation_gwd-keyframes;
  -moz-animation: 9s linear 0s 1 normal forwards gwd-gen-qkz5gwdanimation_gwd-keyframes;
}
[data-gwd-group="Cards"] .gwd-grp-ktf1.gwd-div-1pt8 {
  position: absolute;
  left: 50%;
  top: 50%;
  filter: opacity(100%) brightness(1000%);
  -webkit-filter: opacity(100%) brightness(1000%);
  transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(-84deg);
  -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(-84deg);
  -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px) rotateY(-84deg);
  visibility: inherit;
}
[data-gwd-group="Cards"] .gwd-grp-ktf1.gwd-div-yhs5 {
  position: absolute;
  left: 50%;
  top: 50%;
  filter: brightness(100%);
  -webkit-filter: brightness(100%);
  transform: translate(-50%, -50%) translate3d(0px, 0px, 0px);
  -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px);
  -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px);
  visibility: inherit;
}
[data-gwd-group="Lootbox"] {
  width: 176px;
  height: 280px;
}
[data-gwd-group="Lootbox"] {
  width: 176px;
  height: 280px;
}
[data-gwd-group="Lootbox"] {
  width: 176px;
  height: 280px;
}
[data-gwd-group="Lootbox"] {
  width: 140px;
  height: 256px;
}
[data-gwd-group="Lootbox"] {
  width: 140px;
  height: 256px;
}
[data-gwd-group="Lootbox"] .gwd-grp-15vh.gwd-img-11iy {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 320px;
  height: 320px;
  filter: opacity(0%);
  -webkit-filter: opacity(0%);
  transform: translate(-50%, -50%) translate3d(0px, 0px, -409px) rotateZ(0deg);
  -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, -409px) rotateZ(0deg);
  -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, -409px) rotateZ(0deg);
  visibility: inherit;
}
[data-gwd-group="Lootbox"] .gwd-grp-15vh.gwd-img-15xe {
  position: absolute;
  width: 1000px;
  height: 667px;
  left: 50%;
  top: 50%;
  filter: opacity(0%);
  -webkit-filter: opacity(0%);
  transform: translate(-50%, -50%) translate3d(0px, 0px, 323px);
  -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 323px);
  -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 323px);
}
[data-gwd-group="Lootbox"] .gwd-grp-15vh.gwd-img-v5gh {
  position: absolute;
  width: 360px;
  height: 360px;
  left: 50%;
  top: 50%;
  filter: opacity(0%);
  -webkit-filter: opacity(0%);
  transform: translate(-50%, -50%) translate3d(0px, 0px, 301px);
  -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 301px);
  -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 301px);
}
@keyframes gwd-gen-1wazgwdanimation_gwd-keyframes {
  0% {
      visibility: inherit;
      animation-timing-function: step-end;
      -webkit-animation-timing-function: step-end;
      -moz-animation-timing-function: step-end;
  }
  0.1183% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(0%);
      -webkit-filter: opacity(0%);
      transform: translate(-50%, -50%) translate3d(0px, 0px, -409px) rotateZ(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, -409px) rotateZ(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, -409px) rotateZ(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  28.4024% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  31.9527% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(5deg);
      -webkit-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(5deg);
      -moz-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(5deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  36.6864% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  39.645% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(-5deg);
      -webkit-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(-5deg);
      -moz-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(-5deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  43.787% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  49.1124% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(5deg);
      -webkit-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(5deg);
      -moz-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(5deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  52.6627% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  57.3964% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(-5deg);
      -webkit-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(-5deg);
      -moz-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(-5deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  60.355% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  64.497% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(5deg);
      -webkit-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(5deg);
      -moz-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(5deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  69.8225% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  73.3728% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(-5deg);
      -webkit-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(-5deg);
      -moz-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(-5deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  78.1065% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  81.0651% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(5deg);
      -webkit-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(5deg);
      -moz-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(5deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  85.2071% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  90.5325% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(-5deg);
      -webkit-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(-5deg);
      -moz-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(-5deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  94.0828% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  99.8817% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(0deg);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(0deg);
      -webkit-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(0deg);
      -moz-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(0deg);
      visibility: hidden;
  }
}
@-webkit-keyframes gwd-gen-1wazgwdanimation_gwd-keyframes {
  0% {
      visibility: inherit;
      -webkit-animation-timing-function: step-end;
  }
  0.1183% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(0%);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, -409px) rotateZ(0deg);
      -webkit-animation-timing-function: linear;
  }
  28.4024% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      -webkit-animation-timing-function: linear;
  }
  31.9527% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(5deg);
      -webkit-animation-timing-function: linear;
  }
  36.6864% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      -webkit-animation-timing-function: linear;
  }
  39.645% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(-5deg);
      -webkit-animation-timing-function: linear;
  }
  43.787% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      -webkit-animation-timing-function: linear;
  }
  49.1124% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(5deg);
      -webkit-animation-timing-function: linear;
  }
  52.6627% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      -webkit-animation-timing-function: linear;
  }
  57.3964% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(-5deg);
      -webkit-animation-timing-function: linear;
  }
  60.355% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      -webkit-animation-timing-function: linear;
  }
  64.497% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(5deg);
      -webkit-animation-timing-function: linear;
  }
  69.8225% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      -webkit-animation-timing-function: linear;
  }
  73.3728% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(-5deg);
      -webkit-animation-timing-function: linear;
  }
  78.1065% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      -webkit-animation-timing-function: linear;
  }
  81.0651% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(5deg);
      -webkit-animation-timing-function: linear;
  }
  85.2071% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      -webkit-animation-timing-function: linear;
  }
  90.5325% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(-5deg);
      -webkit-animation-timing-function: linear;
  }
  94.0828% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      -webkit-animation-timing-function: linear;
  }
  99.8817% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(0deg);
      -webkit-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(0deg);
      visibility: hidden;
  }
}
@-moz-keyframes gwd-gen-1wazgwdanimation_gwd-keyframes {
  0% {
      visibility: inherit;
      -moz-animation-timing-function: step-end;
  }
  0.1183% {
      --gwd-hidden-css-filter: none;
      filter: opacity(0%);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, -409px) rotateZ(0deg);
      -moz-animation-timing-function: linear;
  }
  28.4024% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      -moz-animation-timing-function: linear;
  }
  31.9527% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(5deg);
      -moz-animation-timing-function: linear;
  }
  36.6864% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      -moz-animation-timing-function: linear;
  }
  39.645% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(-5deg);
      -moz-animation-timing-function: linear;
  }
  43.787% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      -moz-animation-timing-function: linear;
  }
  49.1124% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(5deg);
      -moz-animation-timing-function: linear;
  }
  52.6627% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      -moz-animation-timing-function: linear;
  }
  57.3964% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(-5deg);
      -moz-animation-timing-function: linear;
  }
  60.355% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      -moz-animation-timing-function: linear;
  }
  64.497% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(5deg);
      -moz-animation-timing-function: linear;
  }
  69.8225% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      -moz-animation-timing-function: linear;
  }
  73.3728% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(-5deg);
      -moz-animation-timing-function: linear;
  }
  78.1065% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      -moz-animation-timing-function: linear;
  }
  81.0651% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(5deg);
      -moz-animation-timing-function: linear;
  }
  85.2071% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      -moz-animation-timing-function: linear;
  }
  90.5325% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(-5deg);
      -moz-animation-timing-function: linear;
  }
  94.0828% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-transform: translate(-50%, -50%) translate3d(10px, 0px, 100px) rotateZ(0deg);
      -moz-animation-timing-function: linear;
  }
  99.8817% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(0deg);
      -moz-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-transform: translate(-50%, -50%) translate3d(-10px, 0px, 100px) rotateZ(0deg);
      visibility: hidden;
  }
}
[data-gwd-group="Lootbox"] .gwd-gen-1wazgwdanimation {
  animation: 1.69s linear 0s 1 normal forwards gwd-gen-1wazgwdanimation_gwd-keyframes;
  -webkit-animation: 1.69s linear 0s 1 normal forwards gwd-gen-1wazgwdanimation_gwd-keyframes;
  -moz-animation: 1.69s linear 0s 1 normal forwards gwd-gen-1wazgwdanimation_gwd-keyframes;
}
@keyframes gwd-gen-12j8gwdanimation_gwd-keyframes {
  0% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(0%);
      -webkit-filter: opacity(0%);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  49.1179% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  61.8067% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(0%);
      -webkit-filter: opacity(0%);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(0%);
      -webkit-filter: opacity(0%);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
}
@-webkit-keyframes gwd-gen-12j8gwdanimation_gwd-keyframes {
  0% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(0%);
      -webkit-animation-timing-function: linear;
  }
  49.1179% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-animation-timing-function: linear;
  }
  61.8067% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(0%);
      -webkit-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(0%);
      -webkit-animation-timing-function: linear;
  }
}
@-moz-keyframes gwd-gen-12j8gwdanimation_gwd-keyframes {
  0% {
      --gwd-hidden-css-filter: none;
      filter: opacity(0%);
      -moz-animation-timing-function: linear;
  }
  49.1179% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-animation-timing-function: linear;
  }
  61.8067% {
      --gwd-hidden-css-filter: none;
      filter: opacity(0%);
      -moz-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css-filter: none;
      filter: opacity(0%);
      -moz-animation-timing-function: linear;
  }
}
[data-gwd-group="Lootbox"] .gwd-gen-12j8gwdanimation {
  animation: 2.4431s linear 0.49s 1 normal forwards gwd-gen-12j8gwdanimation_gwd-keyframes;
  -webkit-animation: 2.4431s linear 0.49s 1 normal forwards gwd-gen-12j8gwdanimation_gwd-keyframes;
  -moz-animation: 2.4431s linear 0.49s 1 normal forwards gwd-gen-12j8gwdanimation_gwd-keyframes;
}
@keyframes gwd-gen-1qrygwdanimation_gwd-keyframes {
  0% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(0%);
      -webkit-filter: opacity(0%);
      transform: translate(-50%, -50%) translate3d(0px, 0px, 0px);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  4.5455% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(100%);
      -webkit-filter: opacity(100%);
      transform: translate(-50%, -50%) translate3d(0px, 0px, 0px);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: opacity(0%);
      -webkit-filter: opacity(0%);
      transform: translate(-50%, -50%) translate3d(0px, 0px, 301px);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 301px);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 301px);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
}
@-webkit-keyframes gwd-gen-1qrygwdanimation_gwd-keyframes {
  0% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(0%);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px);
      -webkit-animation-timing-function: linear;
  }
  4.5455% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px);
      -webkit-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: opacity(0%);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 301px);
      -webkit-animation-timing-function: linear;
  }
}
@-moz-keyframes gwd-gen-1qrygwdanimation_gwd-keyframes {
  0% {
      --gwd-hidden-css-filter: none;
      filter: opacity(0%);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px);
      -moz-animation-timing-function: linear;
  }
  4.5455% {
      --gwd-hidden-css-filter: none;
      filter: opacity(100%);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px);
      -moz-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css-filter: none;
      filter: opacity(0%);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 301px);
      -moz-animation-timing-function: linear;
  }
}
[data-gwd-group="Lootbox"] .gwd-gen-1qrygwdanimation {
  animation: 0.22s linear 1.68s 1 normal forwards gwd-gen-1qrygwdanimation_gwd-keyframes;
  -webkit-animation: 0.22s linear 1.68s 1 normal forwards gwd-gen-1qrygwdanimation_gwd-keyframes;
  -moz-animation: 0.22s linear 1.68s 1 normal forwards gwd-gen-1qrygwdanimation_gwd-keyframes;
}
@keyframes gwd-gen-am34gwdanimation_gwd-keyframes {
  0% {
      visibility: inherit;
      animation-timing-function: step-end;
      -webkit-animation-timing-function: step-end;
      -moz-animation-timing-function: step-end;
  }
  0.1% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: brightness(100%);
      -webkit-filter: brightness(100%);
      transform: translate(-50%, -50%) translate3d(0px, 0px, 0px);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  25% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: brightness(100%);
      -webkit-filter: brightness(100%);
      transform: translate(-50%, -50%) translate3d(0px, 0px, 0px);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  50% {
      transform: translate(-50%, -50%) translate3d(0px, -1px, 83px);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, -1px, 83px);
      -moz-transform: translate(-50%, -50%) translate3d(0px, -1px, 83px);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  85% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: brightness(1000%);
      -webkit-filter: brightness(1000%);
      transform: translate(-50%, -50%) translate3d(0px, 0px, 199px);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 199px);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 199px);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  99.9% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: brightness(100%);
      -webkit-filter: brightness(100%);
      transform: translate(-50%, -50%) translate3d(0px, 0px, 322px);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 322px);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 322px);
      animation-timing-function: linear;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css-filter: none;
      --gwd-hidden-css--webkit-filter: none;
      filter: brightness(100%);
      -webkit-filter: brightness(100%);
      transform: translate(-50%, -50%) translate3d(0px, 0px, 322px);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 322px);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 322px);
      visibility: hidden;
  }
}
@-webkit-keyframes gwd-gen-am34gwdanimation_gwd-keyframes {
  0% {
      visibility: inherit;
      -webkit-animation-timing-function: step-end;
  }
  0.1% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: brightness(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px);
      -webkit-animation-timing-function: linear;
  }
  25% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: brightness(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px);
      -webkit-animation-timing-function: linear;
  }
  50% {
      -webkit-transform: translate(-50%, -50%) translate3d(0px, -1px, 83px);
      -webkit-animation-timing-function: linear;
  }
  85% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: brightness(1000%);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 199px);
      -webkit-animation-timing-function: linear;
  }
  99.9% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: brightness(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 322px);
      -webkit-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css--webkit-filter: none;
      -webkit-filter: brightness(100%);
      -webkit-transform: translate(-50%, -50%) translate3d(0px, 0px, 322px);
      visibility: hidden;
  }
}
@-moz-keyframes gwd-gen-am34gwdanimation_gwd-keyframes {
  0% {
      visibility: inherit;
      -moz-animation-timing-function: step-end;
  }
  0.1% {
      --gwd-hidden-css-filter: none;
      filter: brightness(100%);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px);
      -moz-animation-timing-function: linear;
  }
  25% {
      --gwd-hidden-css-filter: none;
      filter: brightness(100%);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 0px);
      -moz-animation-timing-function: linear;
  }
  50% {
      -moz-transform: translate(-50%, -50%) translate3d(0px, -1px, 83px);
      -moz-animation-timing-function: linear;
  }
  85% {
      --gwd-hidden-css-filter: none;
      filter: brightness(1000%);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 199px);
      -moz-animation-timing-function: linear;
  }
  99.9% {
      --gwd-hidden-css-filter: none;
      filter: brightness(100%);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 322px);
      -moz-animation-timing-function: linear;
  }
  100% {
      --gwd-hidden-css-filter: none;
      filter: brightness(100%);
      -moz-transform: translate(-50%, -50%) translate3d(0px, 0px, 322px);
      visibility: hidden;
  }
}
[data-gwd-group="Cards"] .gwd-gen-am34gwdanimation {
  animation: 2s linear 0s 1 normal forwards gwd-gen-am34gwdanimation_gwd-keyframes;
  -webkit-animation: 2s linear 0s 1 normal forwards gwd-gen-am34gwdanimation_gwd-keyframes;
  -moz-animation: 2s linear 0s 1 normal forwards gwd-gen-am34gwdanimation_gwd-keyframes;
}
[data-gwd-group="Cards"] .gwd-grp-ktf1.gwd-div-79ez {
  visibility: hidden;
}
[data-gwd-group="Cards"] .gwd-grp-ktf1.gwd-div-12op {
  visibility: hidden;
}
[data-gwd-group="Cards"] .gwd-grp-ktf1.gwd-div-rohz {
  visibility: hidden;
}
[data-gwd-group="Cards"] .gwd-grp-ktf1.gwd-div-bzsy {
  visibility: hidden;
}
[data-gwd-group="Cards"] .gwd-grp-ktf1.gwd-div-104y {
  visibility: hidden;
}
[data-gwd-group="Cards"] .gwd-grp-ktf1.gwd-div-1wju {
  visibility: hidden;
}
[data-gwd-group="Cards"] .gwd-grp-ktf1.gwd-div-rgm1 {
  visibility: hidden;
}
[data-gwd-group="Cards"] .gwd-grp-ktf1.gwd-div-er2s {
  visibility: hidden;
}
[data-gwd-group="Cards"] .gwd-grp-ktf1.gwd-div-jj2w {
  visibility: hidden;
}
[data-gwd-group="Cards"] .gwd-grp-ktf1.gwd-div-1pt8 {
  visibility: hidden;
}

[data-gwd-group="Cards"] .gwd-gen-qkz5gwdanimation-1-card {
    animation: 5.4s linear 0s 1 normal forwards gwd-gen-qkz5gwdanimation_gwd-keyframes;
    -webkit-animation: 5.4s linear 0s 1 normal forwards gwd-gen-qkz5gwdanimation_gwd-keyframes;
    -moz-animation: 5.4s linear 0s 1 normal forwards gwd-gen-qkz5gwdanimation_gwd-keyframes;
  }


  @keyframes gwd-gen-46uggwdanimation_gwd-keyframes {
    0% {
        --gwd-hidden-css-filter: none;
        --gwd-hidden-css--webkit-filter: none;
        filter: opacity(0%);
        display: block;
        -webkit-filter: opacity(0%);
        animation-timing-function: linear;
        -webkit-animation-timing-function: linear;
        -moz-animation-timing-function: linear;
    }
    68% {
        --gwd-hidden-css-filter: none;
        --gwd-hidden-css--webkit-filter: none;
        filter: opacity(0%);
        -webkit-filter: opacity(0%);
        animation-timing-function: linear;
        -webkit-animation-timing-function: linear;
        -moz-animation-timing-function: linear;
    }
    72% {
        --gwd-hidden-css-filter: none;
        --gwd-hidden-css--webkit-filter: none;
        filter: opacity(100%);
        -webkit-filter: opacity(100%);
        animation-timing-function: cubic-bezier(1, -0.018, 1, 1);
        -webkit-animation-timing-function: cubic-bezier(1, -0.018, 1, 1);
        -moz-animation-timing-function: cubic-bezier(1, -0.018, 1, 1);
    }
    100% {
        --gwd-hidden-css-filter: none;
        --gwd-hidden-css--webkit-filter: none;
        filter: opacity(0%);
        -webkit-filter: opacity(0%);
        animation-timing-function: linear;
        -webkit-animation-timing-function: linear;
        -moz-animation-timing-function: linear;
        display: none;
    }
}
@-webkit-keyframes gwd-gen-46uggwdanimation_gwd-keyframes {
    0% {
        --gwd-hidden-css--webkit-filter: none;
        -webkit-filter: opacity(0%);
        -webkit-animation-timing-function: linear;
        display: block;
    }
    68% {
        --gwd-hidden-css--webkit-filter: none;
        -webkit-filter: opacity(0%);
        -webkit-animation-timing-function: linear;
    }
    72% {
        --gwd-hidden-css--webkit-filter: none;
        -webkit-filter: opacity(100%);
        -webkit-animation-timing-function: cubic-bezier(1, -0.018, 1, 1);
    }
    100% {
        --gwd-hidden-css--webkit-filter: none;
        -webkit-filter: opacity(0%);
        -webkit-animation-timing-function: linear;
        display: none;
    }
}
@-moz-keyframes gwd-gen-46uggwdanimation_gwd-keyframes {
    0% {
        --gwd-hidden-css-filter: none;
        filter: opacity(0%);
        -moz-animation-timing-function: linear;
        display: block;
    }
    68% {
        --gwd-hidden-css-filter: none;
        filter: opacity(0%);
        -moz-animation-timing-function: linear;
    }
    72% {
        --gwd-hidden-css-filter: none;
        filter: opacity(100%);
        -moz-animation-timing-function: cubic-bezier(1, -0.018, 1, 1);
    }
    100% {
        --gwd-hidden-css-filter: none;
        filter: opacity(0%);
        -moz-animation-timing-function: linear;
        display: none;
    }
}

.mysteryBoxOpening1 .gwd-gen-46uggwdanimation {
    animation: 2.5s linear 0s 1 normal forwards gwd-gen-46uggwdanimation_gwd-keyframes;
    -webkit-animation: 2.5s linear 0s 1 normal forwards gwd-gen-46uggwdanimation_gwd-keyframes;
    -moz-animation: 2.5s linear 0s 1 normal forwards gwd-gen-46uggwdanimation_gwd-keyframes;
}

.gwd-div-1jsp {
    
    display: 0;
    background-color: rgb(255, 255, 255);
    filter: opacity(0%);
    -webkit-filter: opacity(0%);

    position: fixed; /* fixed positioning will make it relative to the viewport */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
}


.mysteryBoxOpening1 img {
    max-width: none;
}

.mysteryBoxOpening1 .mysteryBox3D {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    perspective: 1400px;
    -webkit-perspective: 1400px;
    -moz-perspective: 1400px;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
}

/*

@media (max-width: 1280px) {
    .mysteryBoxOpening1 .contentAmounts1 {
        transform: scale(1);
    }
    .mysteryBoxOpening1 .contentAmounts2 {
        transform: scale(0.7);
    }
    .mysteryBoxOpening1 .contentAmounts3 {
        transform: scale(0.7);
    }
    .mysteryBoxOpening1 .contentAmounts4 {
        transform: scale(0.7);
    }
    .mysteryBoxOpening1 .contentAmounts5 {
        transform: scale(0.6);
    }
}

@media (max-width: 640px) {
    .mysteryBoxOpening1 .contentAmounts1 {
        transform: scale(1);
    }
    .mysteryBoxOpening1 .contentAmounts2 {
        transform: scale(0.65);
    }
    .mysteryBoxOpening1 .contentAmounts3 {
        transform: scale(0.5);
    }
    .mysteryBoxOpening1 .contentAmounts4 {
        transform: scale(0.35);
    }
    .mysteryBoxOpening1 .contentAmounts5 {
        transform: scale(0.35);
    }
}


@media (max-width: 480px) {
    .mysteryBoxOpening1 .contentAmounts1 {
        transform: scale(1);
    }
    .mysteryBoxOpening1 .contentAmounts2 {
        transform: scale(0.6);
    }
    .mysteryBoxOpening1 .contentAmounts3 {
        transform: scale(0.45);
    }
    .mysteryBoxOpening1 .contentAmounts4 {
        transform: scale(0.35);
    }
    .mysteryBoxOpening1 .contentAmounts5 {
        transform: scale(0.25);
    }
}

*/
